<template>
  <div class="pano">
    <PanoramaLoading class="pano" />
    <div
      class="pano"
      ref="panocontainer"
      tabindex="-1"
      name="marzipano-room"
      data-testid="marzipano"
    ></div>
    <PanoramaLoading class="pano" v-if="scenesLoading" />
    <slot v-if="scene !== undefined" />
    <div v-if="scene !== undefined && rectConfigEnabled">
      <MarzipanoRectConfigurator
        v-if="scene !== undefined"
        :scene="scene"
        @changed="configuratorChanged"
        tabindex="-1"
        :panoPitch="pitch"
        :panoYaw="yaw"
        :panoFOV="fov"
        @update:yaw="yaw = $event"
        @update:pitch="pitch = $event"
        @update:fov="fov = $event"
      />
      <MarzipanoRectHotspot
        v-if="Object.keys(config).length !== 0"
        :yaw="config.yaw"
        :pitch="config.pitch"
        :rotX="config.rotX"
        :rotY="config.rotY"
        :rotZ="config.rotZ"
        :width="config.width"
        :height="config.height"
        :debug="true"
        :scene="scene"
      />
    </div>
  </div>
</template>

<script>
import Marzipano from "marzipano";
import MarzipanoRectHotspot from "./MarzipanoRectHotspot";
import MarzipanoRectConfigurator from "./MarzipanoRectConfigurator";
import PanoramaLoading from "./PanoramaLoading.vue";

import ResizeSensor from "css-element-queries/src/ResizeSensor";
import {VideoAsset} from "./VideoAsset.js"

export default {
  name: "Marzipano",
  props: [
    "multipleScenes",
    "typeOf",
    "tileUrl",
    "data",
    "ready",
    "sasKey",
    "rectConfigEnabled",
    "minYaw",
    "maxYaw",
    "activeScene",
    "activeSceneView",
    "initialViewParameters",
    "panoramaVideo"
  ],
  data: () => ({
    dialog: true,
    firstClick: true,
    rotx: 0.0,
    roty: 0.0,
    scene: undefined,
    videoScene: undefined,
    videoAsset: null,
    scenesLoading: true,
    drawMode: false,
    config: {},
    keyboardConfig: {},
    rotationIncrement: 5,
    transformIncrement: 1,
    yaw: 0,
    pitch: 0,
    fov: 0,
  }),
  components: {
    MarzipanoRectHotspot,
    MarzipanoRectConfigurator,
    PanoramaLoading,
  },
  async mounted() {
    await this.$nextTick();

    var viewer = new Marzipano.Viewer(
      // document.getElementById("panorama-container"),
      this.$refs.panocontainer,
      { stage: { progressive: true } }
    );

    if (this.multipleScenes) {
      console.log({ "this.multipleScenes": this.multipleScenes });
      // Initialize all scenes asynchronously and wait for them to be ready
      Promise.all(
        this.multipleScenes.map((item) =>
          this.initScene({
            file: item.panorama.data.file,
            width: item.panorama.data.width,
            levels: item.panorama.data.levels,
            initialViewParameters: item.panorama.data.initialViewParameters,
            typeOf: item.panorama.typeOf,
            minYaw: item.panorama.min_yaw || -181,
            maxYaw: item.panorama.max_yaw || 181,
            tileUrl: item.panorama.tiles.split("?")[0],
            sasKey: item.panorama.sas_key,
            viewer,
            item,
          })
        )
      )
        .then((scenes) => {
          // All scenes are initialized
          console.log({ scenes });
          this.scene = scenes[0].scene; // Assuming `initScene` resolves with an object that has a `scene` property
          this.scene.switchTo();
          this.onRenderComplete();

          // Emit events after all scenes are loaded
          this.$emit("loaded", this.scene);
          this.$emit("view", scenes[0].view);
          this.$emit("initialViewParameters", scenes[0].initialViewParameters);
          this.$emit("scenes", scenes);
        })
        .catch((error) => {
          console.error("Error initializing scenes:", error);
          this.scenesLoading = false; // Hide preloader and possibly handle error
        });
    } else {
      // Handle single scene initialization if needed

      this.initScene({
        file: this.data.file,
        width: this.data.width,
        levels: this.data.levels,
        initialViewParameters: this.data.initialViewParameters,
        typeOf: this.typeOf,
        minYaw: this.minYaw,
        maxYaw: this.maxYaw,
        tileUrl: this.tileUrl,
        sasKey: this.sasKey,
        viewer,
      });
      this.$emit("loaded", this.scene);


      this.onRenderComplete();

      if (this.panoramaVideo) {
        this.initVideoScene({
          viewer: viewer, 
          initialViewParameters: this.data.initialViewParameters, 
          minYaw: this.minYaw,
          maxYaw: this.maxYaw,
        })
      }

    }
    if (this.rectConfigEnabled) {
      this.yaw = viewer.view().yaw().toFixed(5);
      this.pitch = viewer.view().pitch().toFixed(5);
      this.fov = viewer.view().fov().toFixed(5);
      var dragControlMethod = viewer
        .controls()
        .method("mouseViewDrag").instance;
      dragControlMethod.addEventListener("inactive", () => {
        this.yaw = viewer.view().yaw().toFixed(5);
        this.pitch = viewer.view().pitch().toFixed(5);
        this.fov = viewer.view().fov().toFixed(5);
        console.log("Drag End", this.yaw, this.pitch, this.fov);
      });
    }
  },
  watch: {
    ready() {
      if (this.ready === true && this.scene && !this.rectConfigEnabled) {
        this.autoRotateScene();
      }
    },
    activeScene() {
      if (this.activeScene) {
        this.activeSceneView.setParameters(this.initialViewParameters);
        this.activeScene.switchTo();
        this.$emit("loaded", this.activeScene);
        this.autoRotateScene();
      }
    },
  },
  methods: {
    emitNewScene(videoSceneActive) {
 
      if (videoSceneActive) {

        const currentYaw = this.scene.view().yaw()
        const currentPitch = this.scene.view().pitch()
        const currentFov = this.scene.view().fov()

        this.videoScene.view().setYaw(currentYaw)
        this.videoScene.view().setPitch(currentPitch)
        this.videoScene.view().setFov(currentFov)

        this.videoAsset.play()

        this.videoScene.switchTo()
        this.$emit("change-scene", this.videoScene)

      } else {

        const currentYaw = this.videoScene.view().yaw()
        const currentPitch = this.videoScene.view().pitch()
        const currentFov = this.videoScene.view().fov()

        this.scene.view().setYaw(currentYaw)
        this.scene.view().setPitch(currentPitch)
        this.scene.view().setFov(currentFov)

        this.scene.switchTo()
        this.$emit("change-scene", this.scene)
      }

    },
    initVideoScene({viewer, initialViewParameters, minYaw, maxYaw}) {

      // Create asset and source.
      const asset = new VideoAsset();
      const source = new Marzipano.SingleAssetSource(asset);

      // Create geometry.
      const geometry = new Marzipano.EquirectGeometry([{ width: 1 }]);

      // Create view.
      const limiter = Marzipano.util.compose(
        Marzipano.RectilinearView.limit.vfov(
          (40 * Math.PI) / 180,
          (80 * Math.PI) / 180
        ),
        Marzipano.RectilinearView.limit.yaw(
          Math.PI * (minYaw / 180),
          Math.PI * (maxYaw / 180)
        ),
        Marzipano.RectilinearView.limit.pitch(
          Math.PI * (-80 / 180),
          Math.PI * (80 / 180)
        )
      );

      const { fov, pitch, yaw } = initialViewParameters
      const view = new Marzipano.RectilinearView({ fov: fov, pitch: pitch, yaw: yaw }, limiter);

      // Create scene.
      this.videoScene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view
      });

      // Start playback on click.
      // Playback cannot start automatically because most browsers require the play()
      // method on the video element to be called in the context of a user action.
      document.body.addEventListener('click', tryStart);
      document.body.addEventListener('touchstart', tryStart);

      // Whether playback has started.
      let started = false;

      // Try to start playback.
      let self = this
      function tryStart() {
        if (started) {
          return;
        }
        started = true;

        let videoUrl = self.panoramaVideo.file

        if(process.env.NODE_ENV === 'development') {
          videoUrl = new URL(
            videoUrl,
            process.env.VUE_APP_BACKEND_URL.slice(0, -1)
          );
        }

        const video = document.createElement('video');

        video.src = videoUrl;
        video.crossOrigin = 'anonymous';

        video.autoplay = true;
        video.loop = true;
        video.muted = true;
        video.controls = true;

        // Prevent the video from going full screen on iOS.
        video.playsInline = true;
        video.webkitPlaysInline = true;

        video.play();

        waitForReadyState(video, video.HAVE_METADATA, 100, function () {
          waitForReadyState(video, video.HAVE_ENOUGH_DATA, 100, function () {
            asset.setVideo(video);
          });
        });

        // Display scene.
        self.videoScene.switchTo();
        self.$emit("change-scene", self.videoScene);
        self.$emit('change-video-scene-active', true)
      }


      // To have reference to the video, see VideoAsset.js for more details
      this.videoAsset = asset

      // Wait for an element to reach the given readyState by polling.
      // The HTML5 video element exposes a `readystatechange` event that could be
      // listened for instead, but it seems to be unreliable on some browsers.
      function waitForReadyState(element, readyState, interval, done) {
        var timer = setInterval(function () {
          if (element.readyState >= readyState) {
            clearInterval(timer);
            done(null, true);
          }
        }, interval);
      }
    },

    autoRotateScene() {
      let autorotate = Marzipano.autorotate({
        yawSpeed: 0.025, // Yaw rotation speed
        targetPitch: 0, // Pitch value to converge to
        targetFov: (80 * Math.PI) / 180, // Fov value to converge to
      });

      this.scene.viewer().startMovement(autorotate);
    },

    onRenderComplete() {
      console.log("Render complete!");
      this.scenesLoading = false;
      this.$emit("walkthroughReady");

      // Implement any action you want to take after the render is complete
    },
    configuratorChanged(data) {
      this.config = data;
    },
    lookToConsole(consoleYaw, consolePitch) {
      var destinationViewParameters = {
        yaw: (consoleYaw * Math.PI) / 60,
        pitch: (consolePitch * Math.PI) / 60,
        fov: (80 * Math.PI) / 180,
      };
      var options = {
        transitionDuration: 2000,
      };
      this.scene.lookTo(destinationViewParameters, options);
    },
    initScene({
      file,
      width,
      levels,
      initialViewParameters,
      typeOf,
      minYaw,
      maxYaw,
      tileUrl,
      sasKey,
      viewer,
      item,
    }) {
      viewer.setIdleMovement(
        5000,
        Marzipano.autorotate({
          yawSpeed: 0.025, // Yaw rotation speed
          targetPitch: 0, // Pitch value to converge to
          targetFov: (80 * Math.PI) / 180, // Fov value to converge to
        })
      );
      console.log(
        this.data,
        this.maxYaw,
        this.minYaw,
        "data from marzipano.vue"
      );
      // console.log(this.typeOf, "typeOf")
      let pano = this.$refs.panocontainer;

      new ResizeSensor(pano, function () {
        viewer.updateSize();
      });

      var view, geometry, source, limiter;


      if (typeOf == "equirect") {
        // Create source.
        source = Marzipano.ImageUrlSource.fromString(file);

        // Create geometry.
        geometry = new Marzipano.EquirectGeometry([{ width: width }]);

        // Create view.
        Marzipano.RectilinearView.limit.yaw(
          Math.PI * (minYaw / 180),
          Math.PI * (maxYaw / 180)
        ),
          //Limiter for Equirect View - Images for Virtual
          (limiter = Marzipano.util.compose(
            Marzipano.RectilinearView.limit.vfov(
              (40 * Math.PI) / 180,
              (120 * Math.PI) / 180
            ),

            Marzipano.RectilinearView.limit.pitch(
              Math.PI * (-80 / 180),
              Math.PI * (80 / 180)
            )
          ));
        view = new Marzipano.RectilinearView(
          { yaw: 10, fov: (Math.PI * 90) / 180 },
          limiter
        );
      } else {
        var urlPrefix = tileUrl;

        source = Marzipano.ImageUrlSource.fromString(
          urlPrefix + "/{z}/{f}/{y}/{x}.jpg?" + sasKey,
          { cubeMapPreviewUrl: urlPrefix + "/preview.jpg?" + sasKey }
        );

        // Create geometry.
        geometry = new Marzipano.CubeGeometry(levels);


        limiter = Marzipano.util.compose(
          Marzipano.RectilinearView.limit.vfov(
            (40 * Math.PI) / 180,
            (80 * Math.PI) / 180
          ),
          Marzipano.RectilinearView.limit.yaw(
            Math.PI * (minYaw / 180),
            Math.PI * (maxYaw / 180)
          ),
          Marzipano.RectilinearView.limit.pitch(
            Math.PI * (-80 / 180),
            Math.PI * (80 / 180)
          )
        );

        view = new Marzipano.RectilinearView(initialViewParameters, limiter);
      }


      // Create scene.

      if (this.multipleScenes) {
        const scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: false,
        });

        return {
          scene,
          view,
          initialViewParameters,
          item,
        };
      } else if (this.typeOf == "equirect") {
        // Create scene.
        this.scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: false,
        });

        // Display scene.
        this.scene.switchTo();

        this.autoRotateScene();
      } else {
        this.scene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
          pinFirstLevel: false,
        });

        // Display scene.
        this.scene.switchTo();
      }
    },
  },
};
</script>

<style scoped>
.pano {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: visible !important;
}

.frame {
  width: 1000px;
  height: 1000px;
  background-color: rgba(255, 0, 0, 0.5);
}

.controls {
  position: absolute;
  left: 10px;
  /* right: 10px; */
  top: 10px;
  z-index: 5;
}

.loading-transparent {
  opacity: 0.5;
}
</style>
